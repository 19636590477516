import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

/**
 * 인증 가드 Fn
 *
 * @remarks
 *
 * 로그아웃 라우팅 설정 없이 redirect 설정만 있으면
 * 무한 loop 에 빠지므로 주의
 */
export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const authService = inject(AuthService);

  // 토큰이 아예 없으면 리프레시도 불가능하므로
  if (!authService.refreshToken) {
    // 로그아웃
    return router.parseUrl('/logout');
  }

  return authService.getAccount$().pipe(
    map((account) => {
      if (account) {
        return true;
      }

      return router.parseUrl('/logout');
    }),
    catchError(() => {
      return of(router.parseUrl('/logout'));
    }),
  );
};
