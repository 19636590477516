import { ComponentType } from '@angular/cdk/portal';
import { Location } from '@angular/common';
import { InjectionToken, Type, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/lib/auth/auth.service';
import { authGuard } from 'src/lib/guards/auth.guard';

/**
 * 로그인 모달 컴포넌트 타입
 */
export const LOGIN_MODAL_COMPONENT = new InjectionToken<ComponentType<any>>(
  'Login modal component',
);

/**
 * 다이얼로그 서비스 타입
 */
export const DIALOG_SERVICE = new InjectionToken<Type<any>>('Dialog service');

/**
 * 로그인 필요한 페이지 접근 시도하면 로그인 요구하는 가드
 *
 * 로그인 하지 않은 상태로 로그인 필요 페이지 접근 시도시 로그인 모달 표시
 *
 * 홈페이지 전용
 */
export const loginGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const authService = inject(AuthService);

  const loginModalComponentType = inject(LOGIN_MODAL_COMPONENT);
  const dialogServiceType = inject(DIALOG_SERVICE);
  const dialogService = inject(dialogServiceType);
  // SSR 대비하여 window.location 대신 서비스 이용
  const location = inject(Location);

  if (!authService.refreshToken) {
    // 로그인 시도
    return dialogService.openComponent(loginModalComponentType).pipe(
      map((ok) => {
        // 로그인
        if (ok) {
          return true;
        }

        // 현재 위치와 이동하려는 위치가 동일하면 주소창에 입력한것으로 간주
        if (state.url === location.path(true)) {
          // 로그인 창을 그냥 닫은것이므로 홈으로 이동
          return router.parseUrl('/home');
        }

        // 다른 페이지에서 이동을 시도한것이므로 현재 페이지에 머무름
        return false;
      }),
    );
  }

  return authGuard(route, state);
};
